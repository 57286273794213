import { Tooltip, Modal } from "./_bootstrap";
import "./_category";

$(document).ready(function () {
  // const observer = lozad(); // lazy loads elements with default selector as '.lozad'
  // observer.observe();
  // $(document).ajaxStop(function () {
  //   observer.observe();
  // });

  // Highlight any found errors
  $(".text-danger").each(function () {
    var element = $(this).parent().parent();

    if (element.hasClass("form-group")) {
      element.addClass("has-error");
    }
  });

  document
    .querySelectorAll('[data-toggle="tooltip"]')
    .forEach((tooltipElement) => new Tooltip(tooltipElement));

  // Makes tooltips work on ajax generated content
  $(document).ajaxStop(function () {
    document
      .querySelectorAll('[data-toggle="tooltip"]')
      .forEach((tooltipElement) => new Tooltip(tooltipElement));
  });

  //search bar header
  var searchContainer = ".nav-search-holder";
  $(document)
    .on("click", '[data-action="showSearch"]', function () {
      $(searchContainer).slideToggle("fast");
      if (!$(this).hasClass("active")) {
        $(searchContainer).find(".form-control").focus();
      }
      $(this).toggleClass("active");
    })
    .on("click", '[data-action="closeSearch"]', function () {
      $(searchContainer).slideUp("fast");
      $('[data-action="showSearch"]').removeClass("active");
    });

  $(document)
    .on("click", '[data-action="fastOrder"]', loadFastOrderForm)
    .on("click", '[data-action="toCart"]', function () {
      const productId = $(this).data("id");
      cart.add(productId, 1);
    })
    .on("click", '[data-action="showLoginForm"]', function (e) {
      e.preventDefault();
      openLoginForm();
    });

  var toTop = $("#toTop");

  $(window).scroll(function () {
    var $this = $(this);
    $this.scrollTop() > 200
      ? toTop.addClass("visible")
      : toTop.removeClass("visible");
  });

  toTop.click(function () {
    $("html, body").animate({ scrollTop: 0 }, 500);
    return false;
  });
});

function loadFastOrderForm() {
  if ($("#modalFastOrderForm").length > 0) {
    $("#modalFastOrderForm").remove();
  }

  if ($(this).data("productId")) {
    $.get(
      "index.php?route=product/fast_order",
      { request_product_id: $(this).data("productId") },
      function (data) {
        $("body").append(data);
        document
          .querySelectorAll('[data-toggle="tooltip"]')
          .forEach((tooltipElement) => {
            let foTooltip = Tooltip.getInstance(tooltipElement);
            if (typeof foTooltip !== "undefined") {
              foTooltip.hide();
            }
          });
        setTimeout(function () {
          $("#modalFastOrderForm").modal("show");
        }, 100);
      }
    );
  }
}

function openLoginForm() {
  if ($("#loginFormModal").length < 1) {
    $.get("index.php?route=account/login/getForm", function (data) {
      $("body").append(data);
      setTimeout(function () {
        $("#loginFormModal").modal("show");
      }, 100);
    });
  } else {
    $("#loginFormModal").modal("show");
  }
}

window.Modal = Modal;
window.Tooltip = Tooltip;

// Cart add remove functions
var cart = {
  change: function (el, key) {
    let min = 1,
      qtyEl = $(el),
      qtyVal = parseInt(qtyEl.val().match(/\d+/g), 10);

    qtyVal = qtyVal > min && !Number.isNaN(qtyVal) ? qtyVal : min;

    this.update(key, qtyVal);
  },
  plus: function (el, key) {
    let min = 1,
      qtyEl = $(el).closest(".cart-quantity").find('input[name^="quantity"]'),
      qtyVal = parseInt(qtyEl.val().match(/\d+/g), 10);

    qtyVal = !Number.isNaN(qtyVal) ? ++qtyVal : min;

    this.update(key, qtyVal);
  },
  minus: function (el, key) {
    let min = 1,
      qtyEl = $(el).closest(".cart-quantity").find('input[name^="quantity"]'),
      qtyVal = parseInt(qtyEl.val().match(/\d+/g), 10);

    qtyVal = qtyVal > min && !Number.isNaN(qtyVal) ? --qtyVal : min;

    this.update(key, qtyVal);
  },
  add: function (product_id, quantity) {
    $.ajax({
      url: "index.php?route=checkout/cart/add",
      type: "post",
      data:
        "product_id=" +
        product_id +
        "&quantity=" +
        (typeof quantity != "undefined" ? quantity : 1),
      dataType: "json",
      success: function (json) {
        $(".alert-dismissible, .text-danger").remove();

        if (json["redirect"]) {
          location = json["redirect"];
        }

        document
          .querySelectorAll('[data-toggle="tooltip"]')
          .forEach((tooltipElement) => {
            let foTooltip = Tooltip.getInstance(tooltipElement);
            if (typeof foTooltip !== "undefined") {
              foTooltip.hide();
            }
          });

        if (json["success"]) {
          // Need to set timeout otherwise it wont update the total
          setTimeout(function () {
            $("#headerCartIcon").addClass("s-header__active");
            $("#headerCartIcon .s-header__counter").html(json["total"]);
          }, 100);

          $.get("index.php?route=common/cart/info", function (data) {
            $(".popupCartContent").html(
              $(data).find(".popupCartContent").html()
            );

            const modalHeaderCart = new Modal("#modalHeaderCart", {});
            modalHeaderCart.show();
          });
        }
      },
    });
  },
  update: function (key, quantity) {
    $.ajax({
      url: "index.php?route=checkout/cart/edit",
      type: "post",
      data:
        "key=" +
        key +
        "&quantity=" +
        (typeof quantity != "undefined" ? quantity : 1),
      dataType: "json",
      success: function (json) {
        // Need to set timeout otherwise it wont update the total
        setTimeout(function () {
          $("#headerCartIcon .s-header__counter").html(json["total"]);
        }, 100);

        $.get("index.php?route=common/cart/info", function (data) {
          $(".popupCartContent").html($(data).find(".popupCartContent").html());
        });
      },
    });
  },
  remove: function (key) {
    $.ajax({
      url: "index.php?route=checkout/cart/remove",
      type: "post",
      data: "key=" + key,
      dataType: "json",
      success: function (json) {
        // Need to set timeout otherwise it wont update the total
        setTimeout(function () {
          $("#headerCartIcon .s-header__counter").html(json["total"]);
          if (json["total"] < 1) {
            $("#headerCartIcon").removeClass("s-header__active");
          }
        }, 100);

        $.get("index.php?route=common/cart/info", function (data) {
          $(".popupCartContent").html($(data).find(".popupCartContent").html());
        });
      },
    });
  },
};

window.cart = cart;

var wishlist = {
  add: function (product_id) {
    if (globalConfig.Customer.isLogged) {
      $.ajax({
        url: "index.php?route=account/wishlist/add",
        type: "post",
        data: "product_id=" + product_id,
        dataType: "json",
        success: function (json) {
          $(".alert-dismissible").remove();

          if (json["redirect"]) {
            location = json["redirect"];
          }

          document
            .querySelectorAll('[data-toggle="tooltip"]')
            .forEach((tooltipElement) => {
              let foTooltip = Tooltip.getInstance(tooltipElement);
              if (typeof foTooltip !== "undefined") {
                foTooltip.hide();
              }
            });

          if (json["success"]) {
            toastr.success(json["success"]);
          }

          $("#wishlist-total span").html(json["total"]);
          $("#wishlist-total").attr("title", json["total"]);
        },
      });
    } else {
      openLoginForm();
    }
  },
  remove: function () {},
};

window.wishlist = wishlist;

// Autocomplete */
(function ($) {
  $.fn.autocomplete = function (option) {
    return this.each(function () {
      this.timer = null;
      this.items = new Array();

      $.extend(this, option);

      $(this).attr("autocomplete", "off");

      // Focus
      $(this).on("focus", function () {
        this.request();
      });

      // Blur
      $(this).on("blur", function () {
        setTimeout(
          function (object) {
            object.hide();
          },
          200,
          this
        );
      });

      // Keydown
      $(this).on("keydown", function (event) {
        switch (event.keyCode) {
          case 27: // escape
            this.hide();
            break;
          default:
            this.request();
            break;
        }
      });

      // Click
      this.click = function (event) {
        event.preventDefault();

        var value = $(event.target).closest("li").attr("data-value");

        if (value && this.items[value]) {
          this.select(this.items[value]);
        }
      };

      // Show
      this.show = function () {
        var pos = $(this).position();

        $(this)
          .siblings("ul.dropdown-menu")
          .css({
            top: pos.top + $(this).outerHeight(),
            left: pos.left,
          });

        // $(this).siblings("ul.dropdown-menu").show();
        $(this).siblings("ul.dropdown-menu").addClass("show");
      };

      // Hide
      this.hide = function () {
        $(this).siblings("ul.dropdown-menu").removeClass("show");
        // $(this).siblings("ul.dropdown-menu").hide();
      };

      // Request
      this.request = function () {
        clearTimeout(this.timer);

        this.timer = setTimeout(
          function (object) {
            object.source($(object).val(), $.proxy(object.response, object));
          },
          200,
          this
        );
      };

      // Response
      this.response = function (json) {
        let html = "";

        if (json.length) {
          for (let i = 0; i < json.length; i++) {
            this.items[json[i]["value"]] = json[i];
          }

          for (let i = 0; i < json.length; i++) {
            if (!json[i]["category"]) {
              html +=
                '<li data-value="' +
                json[i]["value"] +
                '"><a href="#">' +
                json[i]["label"] +
                "</a></li>";
            }
          }

          // Get all the ones with a categories
          var category = new Array();

          for (let i = 0; i < json.length; i++) {
            if (json[i]["category"]) {
              if (!category[json[i]["category"]]) {
                category[json[i]["category"]] = new Array();
                category[json[i]["category"]]["name"] = json[i]["category"];
                category[json[i]["category"]]["item"] = new Array();
              }

              category[json[i]["category"]]["item"].push(json[i]);
            }
          }

          for (let i in category) {
            html +=
              '<li class="dropdown-header">' + category[i]["name"] + "</li>";

            for (j = 0; j < category[i]["item"].length; j++) {
              html +=
                '<li data-value="' +
                category[i]["item"][j]["value"] +
                '"><a href="#">&nbsp;&nbsp;&nbsp;' +
                category[i]["item"][j]["label"] +
                "</a></li>";
            }
          }
        }

        if (html) {
          this.show();
        } else {
          this.hide();
        }

        $(this).siblings("ul.dropdown-menu").html(html);
      };

      $(this).after('<ul class="dropdown-menu"></ul>');
      $(this)
        .siblings("ul.dropdown-menu")
        .delegate("a", "click", $.proxy(this.click, this));
    });
  };
})(window.jQuery);

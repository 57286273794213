$(document).on("mouseup", '[data-action="sortProducts"]', function () {
  var $this = $(this);
  var url_link = $this.attr("data-href");

  if (url_link !== "undefined" && url_link) {
    sortProducts(url_link, $this, ".js-sortReload");
  }
});

$(".s-pagination").on("click", "#showMoreData", function () {
  var link = $(".s-pagination").first().find(".active").next().find("[href]");

  if (link.length) {
    sortProducts(link.attr("href"), $(this), false);
  }
});

function setLocation(curLoc) {
  try {
    history.pushState(null, null, curLoc);
    return;
  } catch (e) {}
  location.hash = "#" + curLoc;
}

function sortProducts(url_link, $this, sortRow = true) {
  var paginator = ".s-pagination";
  var conatiner = ".s-products";

  if (url_link) {
    $.ajax({
      url: url_link,
      type: "get",
      cache: false,
      dataType: "html",
      beforeSend: function () {
        $(conatiner).css("opacity", 0.5);
        $this.addClass("animated").prop("disabled", true);
      },
      complete: function () {
        $(conatiner).attr("style", null);
        $this.removeClass("animated").prop("disabled", false);
      },
      success: function (html) {
        if (html) {
          var $html = $(html);
          var $prodCont = $html.find(conatiner);
          var $paginCont = $html.find(paginator);

          if ($prodCont.length) {
            if (sortRow) {
              var $sortRow = $html.find(sortRow);
              $(sortRow).each(function (index, el) {
                $(el).html($sortRow.eq(index).html());
              });
              $(conatiner).html($prodCont.html());
            } else {
              $(conatiner).append($prodCont.html());
            }
            $(paginator).each(function (index, el) {
              $(el).html($paginCont.eq(index).html());
            });
          }

          setLocation(url_link);
        }
      },
      error: function () {
        console.log("error");
      },
    });
  }
}
